import React from 'react';
import { Switch, Route } from 'react-router-dom';
import './App.css';
import Home from './components/Home';
import MainMenu from './components/MainMenu';
import FutureOiTableComponent from './components/FutureOiTableComponent';
import Footer from './components/Footer';

function App() {
  return (
    <div>
      <div className="header row">
        <MainMenu />
      </div>
      <div className="body row">
        <Switch>
          <Route path="/" component={Home} exact />
          <Route path="/futureOI" component={FutureOiTableComponent} />
          <Route component={Error} />
          
        </Switch>
      </div>
      <div className="footer row">
        <Footer />
      </div>      
    </div>
  );
}

export default App;

import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';
import Switch from '@material-ui/core/Switch';
import MuiAlert from '@material-ui/lab/Alert';

import ReactTable from 'react-table-6';

import Underlying from '../elements/Underlying';
import Year from '../elements/Year';
import Month from '../elements/Month';

import 'react-table-6/react-table.css';
import '../ISAT.css';

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: '100%',
    },
});

export default function FutureOiTableComponent() {
    const months = ['JAN','FEB','MAR','APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC'];

    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(false);
    const [data, setData] = useState([]);
    const [skipAfTxn, toggleAfTxn] = useState(false);
    
    const [underlying, setUnderlying] = useState("HSI");
    const [year, setYear] = useState(new Date().getFullYear());
    const [month, setMonth] = useState(months[new Date().getMonth()]);

    const handleChangeUnderlying = event => {
        setUnderlying(event.target.value);
        console.log(event.target.value);
    }

    const handleChangeYear = event => {
        setYear(event.target.value);
        console.log(event.target.value);
    }

    const handleChangeMonth = event => {
        setMonth(event.target.value);
        console.log(event.target.value);
    }

    const fetchData = async () => {
        console.log("Loading data....");
        setLoading(true);

        // const URL = "http://localhost:8080/api/indexFuture/" + underlying + "/" + year + "/" + month;
        // const URL = "http://isat-restws.bearsoft.technology:8080/api/indexFuture/" + underlying + "/" + year + "/" + month;
        const URL = "https://omscvoe8wc.execute-api.ap-southeast-1.amazonaws.com/api/indexFuture/" + underlying + "/" + year + "/" + month;
        console.log(URL);

        await axios
            .get(URL)
            .then(res => {
                console.log(res);
                setData(res.data);
            })
            .catch(err => {
                console.log(err);
                setErrors(err);
            })
        
        setLoading(false);
    }

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [underlying, year, month]);

    const handleChange = () => event => {
        toggleAfTxn(event.target.checked);
    };

    return (
        <div>
            <Paper className={classes.root}>
                <TableContainer className={classes.container}>
                    <Table padding="default">
                        <TableHead>
                            <TableRow>
                                <TableCell width="130" padding="none">
                                    <Underlying underlying={underlying} onChangeUnderlying={handleChangeUnderlying} />
                                </TableCell>
                                <TableCell width="130" padding="none">
                                    <Year year={year} onChangeYear={handleChangeYear} />
                                </TableCell>
                                <TableCell width="130" padding="none">
                                    <Month month={month} onChangeMonth={handleChangeMonth} />
                                </TableCell>
                                <TableCell align="right">
                                    <Switch
                                        checked = {skipAfTxn}
                                        onChange = {handleChange('skipAfTxn')}
                                    />&nbsp;Skip after hour period transactions
                                </TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>
                </TableContainer>
            </Paper>
            { errors && <MuiAlert elevation={6} variant="filled" severity="error">errors</MuiAlert> }
            <p />{ loading && <LinearProgress color="secondary" /> }            
            <ReactTable
                data={data}
                defaultPageSize={10}
                sortable={false}
                resizable={false}
                columns={[
                    {
                        Header: "Business Date",
                        accessor: "businessDate",
                    },
                    {
                        Header: "Gross OI",
                        columns: [
                            {
                                Header: "Total",
                                accessor: "volumeData.grossOI",
                                Cell: row => (<span>{row.value.toLocaleString()}</span>)
                            },
                            {
                                Header: "Change",
                                accessor: "volumeData.grossOIChange",
                                Cell: row => (
                                    row.value &&
                                    <div style={{ 
                                            color: row.value > 0 ? "#000000":"#FF0000",
                                        }}>{row.value.toLocaleString()}
                                    </div>
                                )
                            }
                        ]
                    },
                    {
                        Header: "Net OI",
                        columns: [
                            {
                                Header: "Total",
                                accessor: "volumeData.netOI",
                                Cell: row => (<span>{row.value.toLocaleString()}</span>)
                            },
                            {
                                Header: "Change",
                                accessor: "volumeData.netOIChange",
                                className: "number",
                                Cell: row => (
                                    row.value &&
                                    <div style={{ 
                                        color: row.value > 0 ? "#000000":"#FF0000",
                                    }}>{row.value.toLocaleString()}</div>
                                )
                            }
                        ]
                    },
                    {
                        Header: "Volume",
                        accessor: "volume",
                        Cell: row => (<span>{row.value.toLocaleString()}</span>)
                    },
                    {
                        Header: "Index Position",
                        columns: [
                            {
                                Header: "High",
                                accessor: "highPrice",
                                Cell: row => (
                                    skipAfTxn ? 
                                            row.value.toLocaleString()
                                            : (row.original.afterHourHighPrice > 0 ?
                                                (row.value > row.original.afterHourHighPrice ? row.value.toLocaleString() :  <div style={{color: "#0000FF"}}>{row.original.afterHourHighPrice.toLocaleString()}</div>)
                                                : row.value.toLocaleString())
                                )
                            },
                            {
                                Header: "Low",
                                accessor: "lowPrice",
                                Cell: row => (
                                    skipAfTxn ?
                                            row.value.toLocaleString()
                                            : (row.original.afterHourLowPrice > 0 ?
                                                (row.value < row.original.afterHourLowPrice ? row.value.toLocaleString() : <div style={{color: "#0000FF"}}>{row.original.afterHourLowPrice.toLocaleString()}</div>)
                                                : row.value.toLocaleString())
                                )
                            },
                            {
                                Header: "Open",
                                accessor: "openPrice",
                                Cell: row => (
                                    skipAfTxn ? row.value : <div style={{color: "#0000FF"}}>{row.original.afterHourOpenPrice.toLocaleString()}</div>
                                )
                            },
                            {
                                Header: "Close",
                                accessor: "settlementPrice",
                                Cell: row => (<span>{row.value.toLocaleString()}</span>)
                            },
                            {
                                Header: "Change",
                                accessor: "changeInSettlementPrice",
                                Cell: row => (
                                    <div style={{ 
                                        textAlign: "right",
                                        color: row.value > 0 ? "#000000":"#FF0000",
                                    }}>{row.value.toLocaleString()}</div>
                                )
                            },
                        ]
                    }
                ]}
                style={{
                    height: "650", // This will force the table body to overflow and scroll, since there is not enough room
                    textAlign: "right",
                }}
                className="-striped -highlight"
            />
        </div>
    )
}

import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(theme => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));

function Underlying({underlying, onChangeUnderlying}) {
    const classes = useStyles();
    // const [underlying, setUnderlying] = useState("HSI");

    const inputLabel = useRef(null);
    const [labelWidth, setLabelWidth] = useState(0);
    
    useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
    }, []);

    // const handleChange = event => {
    //     setUnderlying(event.target.value);
    //     console.log(event.target.value);
    // };

    return (        
        <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel ref={inputLabel} id="demo-simple-select-outlined-label">
                Index Future
            </InputLabel>
            <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={underlying}
                onChange={onChangeUnderlying}
                labelWidth={labelWidth}
            >
                <MenuItem value="HSI">HSF</MenuItem>
                <MenuItem value="HHI">HHF</MenuItem>
            </Select>
        </FormControl>        
    )
}

export default Underlying;

import React from "react";

function Home() {
    return (
        <div>
            <h1>Welcome to ISAT Home Page</h1>
            <p>
                Last Update: 31 December 2022
            </p>
        </div>
    )
}

export default Home;
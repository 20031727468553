import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(theme => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(1),
    },
  }));

function Month({month, onChangeMonth}) {
    const classes = useStyles();
    // const [month, setMonth] = useState("MAR");

    const inputLabel = useRef(null);
    const [labelWidth, setLabelWidth] = useState(0);
    
    useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
    }, []);

    return (
        <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel ref={inputLabel} id="demo-simple-select-outlined-label">
                Month
            </InputLabel>
            <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={month}
                onChange={onChangeMonth}
                labelWidth={labelWidth}
            >
                <MenuItem value="JAN">JAN</MenuItem>
                <MenuItem value="FEB">FEB</MenuItem>
                <MenuItem value="MAR">MAR</MenuItem>
                <MenuItem value="APR">APR</MenuItem>
                <MenuItem value="MAY">MAY</MenuItem>
                <MenuItem value="JUN">JUN</MenuItem>
                <MenuItem value="JUL">JUL</MenuItem>
                <MenuItem value="AUG">AUG</MenuItem>
                <MenuItem value="SEP">SEP</MenuItem>
                <MenuItem value="OCT">OCT</MenuItem>
                <MenuItem value="NOV">NOV</MenuItem>
                <MenuItem value="DEC">DEC</MenuItem>
            </Select>
        </FormControl>
    )
}

export default Month;
